'use strict';

import axios from 'axios';
import qs from 'qs';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
	// baseURL: process.env.baseURL || process.env.apiUrl || ""
	// timeout: 60 * 1000, // Timeout
	withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		// console.log(config.headers);
		// console.log(123)
		// console.log(localStorage.getItem('token'))
		Object.assign(config.headers, {
			Authorization: localStorage.getItem('token')
		});
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
_axios.interceptors.response.use(
	async function (response) {
		// Do something with response data
		let {
			data: { code }
		} = response;
		if (code === 20008) {
			let obj = {
				username: 'nt_class',
				password: '123456',
				determine: '1'
			};
			let data = await _axios.post('/api/service/user/checkUserLogin', qs.stringify(obj));
			localStorage.setItem('token', data.headers.token);
			return await _axios(response.config);
		}
		return response;
	},
	function (error) {
		// Do something with response error
		return Promise.reject(error);
	}
);

export default _axios;
