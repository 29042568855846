<template>
	<div class="forecast">
		<div class="header">
			<a-button size="small" icon="left" @click="$emit('gotoPage', 'HomeView')">返回</a-button>
			<div class="cont">
				<b>{{ needData.req.title }}</b>
			</div>
			<a-button size="small" type="primary" @click="wordDrawer.visible = true">选择关键词调整预测方向</a-button>
		</div>

		<div class="content">
			<div class="menu">
				<a-menu mode="vertical" :defaultSelectedKeys="['IPCM']" @select="asideSelect">
					<a-menu-item key="IPCM">主分类预测</a-menu-item>
					<a-menu-item key="IPC">全部分类预测</a-menu-item>
				</a-menu>
			</div>
			<div class="content-inner">
				<a-tabs default-active-key="1" destroyInactiveTabPane>
					<a-tab-pane key="1" tab="IPC分类号推荐">
						<div class="ipc-rec">
							<h3>预测结果</h3>
							<div class="images">
								<div>
									<h3>{{ (ipcRec.ppConf.KEY3_score * 100).toFixed(2) }}%</h3>
									<span>小类</span>
									<b>
										<a-tooltip placement="topLeft" overlayClassName="ipc-box-hover">
											<template slot="title">
												<div style="display: flex; line-height: 24px" v-for="item in ipcRec.ppConf.KEY3_ipc_tree">
													<div style="flex: 0 0 140px">{{ item.classNumber }}</div>
													<div style="flex: 1">{{ item.explanation }}</div>
												</div>
											</template>
											<span style="cursor: pointer">{{ ipcRec.ppConf.KEY3_ipc || '--' }}</span>
										</a-tooltip>
										<a-tooltip v-if="ipcRec.ppConf.KEY3_ipc_explain">
											<template slot="title">{{ ipcRec.ppConf.KEY3_ipc_explain }}</template>
											<a-icon type="info-circle" />
										</a-tooltip>
									</b>
								</div>
								<div>
									<h3>{{ (ipcRec.ppConf.KEY4_score * 100).toFixed(2) }}%</h3>
									<span>大组</span>
									<b>
										<a-tooltip overlayClassName="ipc-box-hover">
											<template slot="title">
												<div style="display: flex; line-height: 24px" v-for="item in ipcRec.ppConf.KEY4_ipc_tree">
													<div style="flex: 0 0 140px">{{ item.classNumber }}</div>
													<div style="flex: 1">{{ item.explanation }}</div>
												</div>
											</template>
											<span style="cursor: pointer">{{ ipcRec.ppConf.KEY4_ipc || '--' }}</span>
										</a-tooltip>
										<a-tooltip v-if="ipcRec.ppConf.KEY4_ipc_explain">
											<template slot="title">{{ ipcRec.ppConf.KEY4_ipc_explain }}</template>
											<a-icon type="info-circle" />
										</a-tooltip>
									</b>
								</div>
								<div>
									<h3>{{ (ipcRec.ppConf.KEY5_score * 100).toFixed(2) }}%</h3>
									<span>小组</span>
									<b>
										<a-tooltip overlayClassName="ipc-box-hover">
											<template slot="title">
												<div style="display: flex; line-height: 24px" v-for="item in ipcRec.ppConf.KEY5_ipc_tree">
													<div style="flex: 0 0 140px">{{ item.classNumber }}</div>
													<div style="flex: 1">{{ item.explanation }}</div>
												</div>
											</template>
											<span style="cursor: pointer">{{ ipcRec.ppConf.KEY5_ipc || '--' }}</span>
										</a-tooltip>
										<a-tooltip v-if="ipcRec.ppConf.KEY5_ipc_explain">
											<template slot="title">{{ ipcRec.ppConf.KEY5_ipc_explain }}</template>
											<a-icon type="info-circle" />
										</a-tooltip>
									</b>
								</div>
							</div>
							<a-radio-group button-style="solid" v-model="ipcRec.castSort">
								<a-radio-button value="a">小类预测</a-radio-button>
								<a-radio-button value="b">大组预测</a-radio-button>
								<a-radio-button value="c">小组预测</a-radio-button>
							</a-radio-group>
							<p class="switch">
								扩展分类号
								<!--                                @change="onChange"-->
								<a-switch v-model="ipcRec.isSwitch" />
							</p>
							<a-table
								ref="ipc-table"
								:columns="ipcRec.tableColumns"
								:data-source="ipcRec.tableData"
								:expandedRowKeys.sync="ipcRec.expandedRowKeys"
								:pagination="false"
								:locale="{ emptyText: '暂无数据' }"
								:rowKey="(record, index) => index">
								<span slot="scoreTitle">
									推荐指数
									<a-tooltip>
										<template slot="title">
											统计与输入文本相似度最高的专利的IPC分类号预测出推荐指数，推荐指数为该分类号在相关专利中出现的频率
											<!--											<template v-if="ipcRec.sortSelect === 'IPCM'">-->
											<!--&lt;!&ndash;												根据前200件专利的IPC分类号小类，统计保护中心可受理的小类数量，预测出推荐指数&ndash;&gt;-->
											<!--                                                统计与输入文本相似度最高的专利的IPC分类号预测出推荐指数，推荐指数为该分类号在相关专利中出现的频率-->
											<!--											</template>-->
											<!--											<template v-if="ipcRec.sortSelect === 'IPC'">-->
											<!--&lt;!&ndash;												根据前1000件专利的IPC分类号小类，统计保护中心可受理的小类数量，预测出推荐指数&ndash;&gt;-->
											<!--                                                统计与输入文本相似度最高的专利的IPC分类号预测出推荐指数，推荐指数为该分类号在相关专利中出现的频率-->
											<!--											</template>-->
										</template>
										<a-icon type="info-circle" />
									</a-tooltip>
								</span>
								<span slot="patentsTitle">
									相关专利
									<a-tooltip>
										<template slot="title">该分类号下前5件相关度大于60%的专利</template>
										<a-icon type="info-circle" />
									</a-tooltip>
								</span>

								<span slot="score" slot-scope="record">{{ (record * 100).toFixed(2) }}%</span>
								<div slot="ipc" slot-scope="record">
									<a-tooltip placement="topLeft" overlayClassName="ipc-box-hover">
										<template slot="title">
											<div style="display: flex; line-height: 24px" v-for="item in record.ipc_tree">
												<div style="flex: 0 0 140px">{{ item.classNumber }}</div>
												<div style="flex: 1">{{ item.explanation }}</div>
											</div>
										</template>
										<span style="color: #1c4cba; cursor: pointer">{{ record.ipc }}</span>
									</a-tooltip>
								</div>
								<p slot="centers" slot-scope="record" style="margin: 0">
									<template v-if="record.centers && record.centers.length">
										{{ record.centers[0].name }}
										<a-tag style="cursor: pointer; margin-left: 5px" @click="proModal_switch(true, record)">
											<template v-if="!(record.centers.length <= 1)">+{{ record.centers.length - 1 }}</template>
											<template v-else>详情</template>
										</a-tag>
									</template>
									<span v-else>无</span>
								</p>
								<div slot="patents" slot-scope="record" style="margin: 0">
									<template v-if="record && record.length">
										<p v-for="(item, index) in record" :key="index">
											<span style="margin-right: 15px">{{ (item.rank * 100).toFixed(2) }}%</span>
											{{ item.title }}
											<a-tag
												color="#FF9355"
												style="margin: 0 0 0 15px"
												v-if="isArraySubset(needData.req.applicantNames, item.applicantNames)">
												同申请人
											</a-tag>
										</p>
									</template>
									<template v-else>
										<p>无</p>
									</template>
								</div>
								<div
									:slot="ipcRec.expandedRowRender"
									slot-scope="record"
									:style="{ margin: `0 0 0 ${ipcRec.expandedRowRenderWidth[0] - 16}px` }">
									<template v-if="record.ipc_extend">
										<div v-for="(item, index) in record.ipc_extend" :key="index" style="display: flex">
											<span
												:style="{
													padding: '0 16px',
													display: 'inline-block',
													flex: `0 0 ${ipcRec.expandedRowRenderWidth[1]}px`
												}">
												{{ item.ipc }}
											</span>
											<p style="padding: 0 16px" :style="{ flex: `0 0 ${ipcRec.expandedRowRenderWidth[2]}px` }">
												{{ item.ipc_explain }}
											</p>
											<span
												style="padding: 0 16px; display: inline-block"
												:style="{ flex: `0 0 ${ipcRec.expandedRowRenderWidth[3]}px` }">
												<template v-if="item.centers && item.centers.length">
													{{ item.centers[0].name }}
													<a-tag style="cursor: pointer; margin-left: 5px" @click="proModal_switch(true, item)">
														<template v-if="!(item.centers.length <= 1)">
															+{{ item.centers.length - 1 }}
														</template>
														<template v-else>详情</template>
													</a-tag>
												</template>
												<template v-else>无保护中心</template>
											</span>
										</div>
									</template>
								</div>
							</a-table>
						</div>
					</a-tab-pane>
					<a-tab-pane key="2" tab="保护中心推荐">
						<div class="pro-rec">
							<h3>
								已关注的保护中心：
								<a-button size="small" type="primary" @click="editProModal_switch(true)">编辑</a-button>
							</h3>
							<div class="tags">
								<a-tag v-for="(item, index) in proRec.centers" :key="index">{{ item }}</a-tag>
							</div>
							<h3>预测结果</h3>
							<p class="switch">
								只看关注的保护中心
								<!--                                @change="onChange"-->
								<a-switch v-model="proRec.isSwitch" />
							</p>
							<a-table
								:columns="proRec.tableColumns"
								:data-source="proRec.tableData"
								:pagination="false"
								:locale="{ emptyText: '暂无数据' }"
								:rowKey="(record, index) => index">
								<span slot="scoreTitle">
									推荐指数
									<a-tooltip>
										<template slot="title">
											根据与输入文本相似度最高的专利的IPC分类号，统计保护中心可受理的相关专利数量预测出推荐指数
										</template>
										<a-icon type="info-circle" />
									</a-tooltip>
								</span>

								<span slot="score" slot-scope="record">{{ (record * 100).toFixed(2) }}%</span>
								<template slot="centersName" slot-scope="record">
									<a-tag v-if="proRec.centers.includes(record)" color="orange" style="margin: 0">
										{{ record }}
									</a-tag>
									<span v-else>
										{{ record }}
									</span>
								</template>
								<div slot="ipc_list" slot-scope="record">
									<template v-if="record.ipc_list && record.ipc_list.length">
										<span style="color: #1777ff; margin-right: 10px">{{ record.ipc_list[0].ipc }}</span>
										{{ record.ipc_list[0].ipc_explain }}
										<a-tag style="cursor: pointer; margin-left: 5px" @click="ipcModal_switch(true, record)">
											+{{ record.ipc_list.length }}
										</a-tag>
									</template>
									<p v-else>无</p>
								</div>
								<template slot="web" slot-scope="record">
									<a :href="record" target="_blank">{{ record }}</a>
								</template>
							</a-table>
						</div>
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>

		<!--    保护中心    -->
		<a-modal
			:title="ipcRec.proModal.title"
			:visible="ipcRec.proModal.visible"
			width="1000px"
			@cancel="proModal_switch(false)"
			:footer="null">
			<div class="forecast-ipcRec-proModal-inner">
				<p>
					分类号：
					<span>{{ ipcRec.proModal.ipcTitle }}</span>
				</p>

				<a-table
					:columns="ipcRec.proModal.tableColumns"
					:data-source="ipcRec.proModal.tableData"
					:pagination="false"
					:locale="{ emptyText: '暂无数据' }"
					:scroll="{ y: 640 }"
					:rowKey="(record, index) => index">
					<span slot="scoreTitle">
						推荐指数
						<a-tooltip>
							<template slot="title">
								根据与输入文本相似度最高的专利的IPC分类号，统计保护中心可受理的相关专利数量预测出推荐指数
							</template>
							<a-icon type="info-circle" />
						</a-tooltip>
					</span>

					<span slot="patentsTitle">
						相关专利
						<a-tooltip>
							<template slot="title">该分类号下前5件相关度大于60%的专利</template>
							<a-icon type="info-circle" />
						</a-tooltip>
					</span>

					<span slot="score" slot-scope="record">{{ (record * 100).toFixed(2) }}%</span>
					<template slot="web" slot-scope="record">
						<a :href="record" target="_blank">{{ record }}</a>
					</template>
				</a-table>
			</div>
		</a-modal>

		<!--    编辑保护中心    -->
		<a-modal
			:title="proRec.editProModal.title"
			:visible="proRec.editProModal.visible"
			width="762px"
			okText="确定"
			cancelText="取消"
			@ok="
				proRec.centers = proRec.editProModal.centers;
				editProModal_switch(false);
			"
			@cancel="editProModal_switch(false)">
			<p>请选择关注的保护中心：</p>
			<a-select
				placeholder="预设关注的保护中心"
				mode="multiple"
				v-model="proRec.editProModal.centers"
				style="width: 714px"
				allowClear>
				<a-select-option
					v-for="(item, index) in commonData.centers"
					:key="index"
					:value="item"
					:disabled="proRec.editProModal.centers.length >= 10 && !proRec.editProModal.centers.includes(item)">
					{{ item }}
				</a-select-option>
			</a-select>
		</a-modal>

		<!--    对应分类号    -->
		<a-modal
			:title="proRec.ipcModal.title"
			:visible="proRec.ipcModal.visible"
			width="1121px"
			@cancel="ipcModal_switch(false)"
			:footer="null">
			<div class="forecast-ipcRec-proModal-inner">
				<p>
					保护中心：
					<span>{{ proRec.ipcModal.ipcTitle }}</span>
				</p>
				<a-table
					:columns="proRec.ipcModal.tableColumns"
					:data-source="proRec.ipcModal.tableData"
					:pagination="false"
					:locale="{ emptyText: '暂无数据' }"
					:scroll="{ y: 640 }"
					:rowKey="(record, index) => index">
					<span slot="scoreTitle">
						推荐指数
						<a-tooltip>
							<template slot="title">
								统计与输入文本相似度最高的专利的IPC分类号预测出推荐指数，推荐指数为该分类号在相关专利中出现的频率
							</template>
							<a-icon type="info-circle" />
						</a-tooltip>
					</span>
					<span slot="patentsTitle">
						相关专利
						<a-tooltip>
							<template slot="title">该分类号下前5件相关度大于60%的专利</template>
							<a-icon type="info-circle" />
						</a-tooltip>
					</span>

					<span slot="score" slot-scope="record">{{ (record * 100).toFixed(2) }}%</span>
					<div slot="patents" slot-scope="record" style="margin: 0">
						<template v-if="record && record.length">
							<p v-for="(item, index) in record" :key="index">
								{{ item.title }}
								<span style="margin: 0 15px">{{ (item.rank * 100).toFixed(2) }}%</span>
								<a-tag
									color="#FF9355"
									style="margin: 0"
									v-if="isArraySubset(needData.req.applicantNames, item.applicantNames)">
									同申请人
								</a-tag>
							</p>
						</template>
						<p v-else>无</p>
					</div>
				</a-table>
			</div>
		</a-modal>

		<a-drawer :width="522" placement="right" :closable="false" :visible="wordDrawer.visible" @close="wordDrawer.visible = false">
			<span slot="title">
				选择关键词调整预测方向
				<a-tooltip>
					<template slot="title">勾选或者输入关键词后，预测方向将向关键词方向靠近</template>
					<a-icon type="question-circle" />
				</a-tooltip>
			</span>

			<div class="forecast-drawer-inner">
				<h3>添加关键词</h3>
				<div style="display: flex">
					<a-input placeholder="添加关键词" v-model.trim="wordDrawer.keyword" allowClear />
					<a-button type="primary" @click="drawerKeyword_add" style="margin-left: 20px">添加</a-button>
				</div>
				<p>系统推荐关键词：</p>
				<a-checkable-tag
					v-for="(item, index) in wordDrawer.sysTags"
					:key="index"
					style="margin: 0 8px 8px 0; cursor: pointer"
					v-model="item.check"
					@change="drawerKeyword_sys($event, item, index)">
					{{ item.label }}
				</a-checkable-tag>
				<p>已选择的关键词：</p>
				<a-tag
					v-for="(item, index) in wordDrawer.myTags"
					:key="item"
					style="margin: 0 8px 8px 0; cursor: pointer"
					closable
					@close="drawerKeyword_del(index)">
					{{ item }}
				</a-tag>
			</div>

			<div
				:style="{
					position: 'absolute',
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e8e8e8',
					padding: '10px 24px',
					textAlign: 'left',
					left: 0,
					background: '#fff',
					borderRadius: '0 0 4px 4px'
				}">
				<a-button type="primary" @click="forecastSubmit" :loading="timeOut.count !== 0 || wordDrawer.loading">
					{{ timeOut.count === 0 ? '重新预测' : `排队中（${timeOut.count}）...` }}
				</a-button>
				<a-button style="margin-left: 12px" @click="wordDrawer.visible = false">取消</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
export default {
	props: ['pageData'],
	data() {
		return {
			needData: this.pageData,
			ipcRec: {
				sortSelect: 'IPCM',
				ppConf: {
					KEY3_score: '',
					KEY4_score: '',
					KEY5_score: '',
					KEY3_ipc: '',
					KEY4_ipc: '',
					KEY5_ipc: ''
				},
				castSort: 'a',
				isSwitch: false,
				expandedRowKeys: [],
				expandedRowRender: '',
				expandedRowRenderWidth: [],
				tableColumns: [
					{
						dataIndex: 'score',
						key: 'score',
						slots: { title: 'scoreTitle' },
						scopedSlots: { customRender: 'score' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '107px'
								}
							};
						}
					},
					{
						title: '分类号',
						key: 'ipc',
						scopedSlots: { customRender: 'ipc' },

						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '120px'
								}
							};
						}
					},
					{
						title: '分类号释义',
						key: 'ipc_explain',
						dataIndex: 'ipc_explain',
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									'max-width': '30vw'
								}
							};
						}
					},
					{
						title: '对应保护中心',
						key: 'centers',
						scopedSlots: { customRender: 'centers' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '270px'
								}
							};
						}
					},
					{
						key: 'patents',
						dataIndex: 'patents',
						slots: { title: 'patentsTitle' },
						scopedSlots: { customRender: 'patents' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									'max-width': '25vw'
								}
							};
						}
					}
				],
				tableData: [],
				proModal: {
					title: '保护中心',
					visible: false,
					ipcTitle: '',
					tableColumns: [
						{
							dataIndex: 'score',
							key: 'score',
							slots: { title: 'scoreTitle' },
							scopedSlots: { customRender: 'score' },
							width: '107px'
						},
						{
							title: '保护中心名称',
							dataIndex: 'name',
							key: 'name'
						},
						{ title: '保护中心领域', dataIndex: 'type', key: 'type' },
						{ title: '网站', dataIndex: 'web', key: 'web', scopedSlots: { customRender: 'web' } },
						{
							title: '电话',
							dataIndex: 'phone',
							key: 'phone'
						}
					],
					tableData: []
				}
			},
			proRec: {
				centers: [],
				isSwitch: false,
				tableColumns: [
					{
						dataIndex: 'score',
						key: 'score',
						slots: { title: 'scoreTitle' },
						scopedSlots: { customRender: 'score' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '107px'
								}
							};
						}
					},
					{
						title: '保护中心名称',
						dataIndex: 'name',
						key: 'name',
						scopedSlots: { customRender: 'centersName' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '240px'
								}
							};
						}
					},
					{
						title: '保护中心领域',
						dataIndex: 'type',
						key: 'type',
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									// 'min-width': '190px'
								}
							};
						}
					},
					{
						title: '网站',
						dataIndex: 'web',
						key: 'web',
						scopedSlots: { customRender: 'web' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									'max-width': '15vw'
								}
							};
						}
					},
					{
						title: '电话',
						dataIndex: 'phone',
						key: 'phone'
						// width: 140
					},
					{
						title: '对应分类号',
						key: 'ipc_list',
						scopedSlots: { customRender: 'ipc_list' },
						customCell: () => {
							return {
								style: {
									// 'width': 'auto',
									'max-width': '35vw',
									'min-width': '17vw'
								}
							};
						}
					}
				],
				tableData: [],
				editProModal: {
					title: '编辑保护中心',
					visible: false,
					centers: []
				},
				ipcModal: {
					title: '对应分类号',
					visible: false,
					ipcTitle: '',
					tableColumns: [
						{
							dataIndex: 'score',
							key: 'score',
							slots: { title: 'scoreTitle' },
							scopedSlots: { customRender: 'score' },
							width: 107
						},
						{ title: '分类号', dataIndex: 'ipc', key: 'ipc', width: 120 },
						{ title: '分类号释义', dataIndex: 'ipc_explain', key: 'ipc_explain' },
						{
							key: 'patents',
							dataIndex: 'patents',
							slots: { title: 'patentsTitle' },
							scopedSlots: { customRender: 'patents' }
						}
					],
					tableData: []
				}
			},
			wordDrawer: {
				visible: false,
				loading: false,
				keyword: '',
				sysTags: [],
				myTags: []
			},
			commonData: {
				centers: []
			},
			timeOut: {
				count: 0,
				time: null
			}
		};
	},
	watch: {
		'ipcRec.sortSelect': function () {
			this.watchCabk(this.needData);
			this.watchCent(this.needData);
		},
		'ipcRec.castSort': function () {
			this.watchCabk(this.needData);
		},
		'ipcRec.isSwitch': function (n) {
			if (n) {
				this.ipcRec.expandedRowRender = 'expandedRowRender';
				this.ipcRec.tableData.forEach((item, index) => {
					this.ipcRec.expandedRowKeys.push(index);
				});
				this.getDomWidth();
			} else {
				this.ipcRec.expandedRowKeys = [];
				this.ipcRec.expandedRowRender = '';
			}
		},
		'proRec.isSwitch': function (nv) {
			this.watchCent(this.needData);
		}
	},
	methods: {
		watchCabk(obj) {
			if (this.ipcRec.sortSelect === 'IPCM') {
				if (this.ipcRec.castSort === 'a') {
					this.ipcRec.tableData = obj.res.IPCM_KEY3;
				}
				if (this.ipcRec.castSort === 'b') {
					this.ipcRec.tableData = obj.res.IPCM_KEY4;
				}
				if (this.ipcRec.castSort === 'c') {
					this.ipcRec.tableData = obj.res.IPCM_KEY5;
				}
				this.ipcRec.ppConf = {
					KEY3_score: obj.res.IPCM_KEY3[0].score,
					KEY4_score: obj.res.IPCM_KEY4[0].score,
					KEY5_score: obj.res.IPCM_KEY5[0].score,
					KEY3_ipc: obj.res.IPCM_KEY3[0].ipc,
					KEY4_ipc: obj.res.IPCM_KEY4[0].ipc,
					KEY5_ipc: obj.res.IPCM_KEY5[0].ipc,
					KEY3_ipc_explain: obj.res.IPCM_KEY3[0].ipc_explain,
					KEY4_ipc_explain: obj.res.IPCM_KEY4[0].ipc_explain,
					KEY5_ipc_explain: obj.res.IPCM_KEY5[0].ipc_explain,
					KEY3_ipc_tree: obj.res.IPCM_KEY3[0].ipc_tree,
					KEY4_ipc_tree: obj.res.IPCM_KEY4[0].ipc_tree,
					KEY5_ipc_tree: obj.res.IPCM_KEY5[0].ipc_tree
				};
			}
			if (this.ipcRec.sortSelect === 'IPC') {
				if (this.ipcRec.castSort === 'a') {
					this.ipcRec.tableData = obj.res.IPC_KEY3;
				}
				if (this.ipcRec.castSort === 'b') {
					this.ipcRec.tableData = obj.res.IPC_KEY4;
				}
				if (this.ipcRec.castSort === 'c') {
					this.ipcRec.tableData = obj.res.IPC_KEY5;
				}
				this.ipcRec.ppConf = {
					KEY3_score: obj.res.IPC_KEY3[0].score,
					KEY4_score: obj.res.IPC_KEY4[0].score,
					KEY5_score: obj.res.IPC_KEY5[0].score,
					KEY3_ipc: obj.res.IPC_KEY3[0].ipc,
					KEY4_ipc: obj.res.IPC_KEY4[0].ipc,
					KEY5_ipc: obj.res.IPC_KEY5[0].ipc,
					KEY3_ipc_explain: obj.res.IPC_KEY3[0].ipc_explain,
					KEY4_ipc_explain: obj.res.IPC_KEY4[0].ipc_explain,
					KEY5_ipc_explain: obj.res.IPC_KEY5[0].ipc_explain,
					KEY3_ipc_tree: obj.res.IPC_KEY3[0].ipc_tree,
					KEY4_ipc_tree: obj.res.IPC_KEY4[0].ipc_tree,
					KEY5_ipc_tree: obj.res.IPC_KEY5[0].ipc_tree
				};
			}
		},
		watchCent(obj) {
			if (this.ipcRec.sortSelect === 'IPCM') {
				this.proRec.tableData = obj.res.ipcm_center.filter(item => {
					return this.proRec.isSwitch ? this.proRec.centers.includes(item.name) : item;
				});
			}
			if (this.ipcRec.sortSelect === 'IPC') {
				this.proRec.tableData = obj.res.ipc_center.filter(item => {
					return this.proRec.isSwitch ? this.proRec.centers.includes(item.name) : item;
				});
			}
		},
		proModal_switch(flag, obj) {
			let _this = this;
			if (obj) {
				_this.ipcRec.proModal.ipcTitle = obj.ipc;
				_this.ipcRec.proModal.tableData = obj.centers;
			}
			_this.ipcRec.proModal.visible = flag;
		},
		ipcModal_switch(flag, obj) {
			let _this = this;
			if (obj) {
				_this.proRec.ipcModal.ipcTitle = obj.name;
				_this.proRec.ipcModal.tableData = obj.ipc_list;
			}
			_this.proRec.ipcModal.visible = flag;
		},
		editProModal_switch(flag, obj) {
			let _this = this;
			if (flag) {
				if (!_this.commonData.centers.length) {
					_this.$axios.get('/api/service/classifyPrediction/getCenterList').then(res => {
						_this.commonData.centers = res.data.data;
					});
				}
			}
			if (obj) {
			}
			_this.proRec.editProModal.visible = flag;
		},
		asideSelect(obj) {
			this.ipcRec.sortSelect = obj.key;
		},
		drawerKeyword_add() {
			if (this.wordDrawer.myTags.indexOf(this.wordDrawer.keyword) === -1 && this.wordDrawer.keyword) {
				this.wordDrawer.myTags.push(this.wordDrawer.keyword);
			}
			this.wordDrawer.keyword = '';
		},
		drawerKeyword_del(index) {
			this.wordDrawer.sysTags.forEach(item => {
				if (item.label === this.wordDrawer.myTags[index]) {
					item.check = false;
				}
			});
			this.wordDrawer.myTags.splice(index, 1);
		},
		drawerKeyword_sys(check, obj, index) {
			let indexOf = this.wordDrawer.myTags.indexOf(obj.label);
			if (check && indexOf === -1) {
				this.wordDrawer.myTags.push(obj.label);
			} else {
				this.drawerKeyword_del(indexOf);
			}
		},
		async forecastSubmit() {
			let _this = this;

			let form = new FormData();
			let uuid = this.$uuid();
			form.append('type', 2);
			form.append('uuid', uuid);
			_this.loading = true;
			let {
				data: { data: count }
			} = await _this.$axios.post('/api/service/companyReview/getReportWaitTime', form);
			_this.loading = false;
			if (count === 0) {
				request_up(uuid);
			} else {
				_this.timeOut.count = count;
				this.timeOut.time = setInterval(async () => {
					_this.timeOut.count--;
					if (_this.timeOut.count === 0) {
						clearInterval(_this.timeOut.time);
						request_up(uuid);
						// await _this.$axios.post('/api/service/companyReview/removeReportWaitTime', { uuid: _this.$uuid });
					}
				}, 1000);
			}

			async function request_up(uuid) {
				let obj = {
					..._this.needData.req,
					...{ centers: _this.proRec.centers, rankWords: _this.wordDrawer.myTags }
				};

				_this.wordDrawer.loading = true;
				let { data } = await _this.$axios.post('/api/service/classifyPrediction/prediction', obj, {
					headers: {
						apiUuid: uuid
					}
				});
				_this.wordDrawer.loading = false;
				if (data.code === 200) {
					_this.$message.success(data.msg);
					_this.needData = { ..._this.needData, ...{ res: data.data } };
					_this.watchCabk(_this.needData);
					_this.wordDrawer.visible = false;
				} else {
					_this.$message.error(data.msg);
				}
			}
		},
		init(obj) {
			this.proRec.centers = obj.req.centers;
			this.watchCent(obj);
			this.watchCabk(obj);
			this.proRec.editProModal.centers = obj.req.centers;
			this.wordDrawer.sysTags = obj.res.keyWords.map(item => {
				return { label: item, check: false };
			});
		},
		isArraySubset(subset, superset) {
			const set = new Set(superset);
			return subset.some(item => set.has(item));
		},
		async getDomWidth() {
			let _this = this;
			_this.$nextTick(() => {
				const ipcTable = this.$refs['ipc-table'].$el;
				const thElements = ipcTable.querySelectorAll('th');
				let arr = Array.from(thElements);
				if (_this.ipcRec.isSwitch) arr.shift();
				arr.forEach((item, index) => {
					const computedStyle = window.getComputedStyle(item);
					const width = parseFloat(computedStyle.width);
					_this.$set(_this.ipcRec.expandedRowRenderWidth, index, parseFloat(width));
				});
			});
		}
	},
	created() {
		this.init(this.needData);
	},
	beforeMount() {},
	async mounted() {
		// this.getDomWidth();
		window.addEventListener('resize', this.getDomWidth);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getDomWidth);
	}
};
</script>

<style lang="less">
.forecast {
	margin-top: 1px;

	& > .header {
		width: 100%;
		height: 47px;
		background: #ffffff;
		box-shadow: 0px 2px 4px 0px rgba(70, 101, 135, 0.19);
		padding: 11.5px 20px;
		margin-bottom: 2px;
		display: flex;

		& > .cont {
			flex: 1;
			margin-left: 30px;
			font-size: 14px;
			font-weight: bold;
			color: #4e5969;
		}
	}

	& > .content {
		display: flex;
		padding: 20px;

		& > .menu {
			flex: 0 0 220px;
		}

		& > .content-inner {
			flex: 1;
			//width: 100%;
			//padding: 0 20px;
			padding-left: 20px;
			//overflow: hidden;

			.ipc-rec,
			.pro-rec {
				& > h3 {
					font-size: 14px;
					font-weight: bold;
					color: #1d2129;
					line-height: 20px;
					padding-top: 20px;
					margin: 0;
				}

				& > .images {
					display: flex;
					margin-top: 30px;

					& > div {
						width: 282px;
						height: 128px;
						background-size: 100% 100%;
						position: relative;

						& + div {
							margin-left: 80px;
						}

						&:nth-of-type(1) {
							background-image: url(@/assets/image/xiaolei_neir.png);
						}

						&:nth-of-type(2) {
							background-image: url(@/assets/image/datu_zhansge.png);
						}

						&:nth-of-type(3) {
							background-image: url(@/assets/image/xiaozu_neirong.png);
						}

						& > h3 {
							font-size: 24px;
							font-weight: bold;
							color: #1777ff;
							position: absolute;
							top: 40px;
							left: 130px;
						}

						& > b {
							font-size: 18px;
							font-weight: bold;
							color: #1d2129;
							position: absolute;
							top: 90px;
							left: 130px;
							display: flex;
							align-items: center;

							& > .anticon {
								font-size: 14px;
								margin-left: 5px;
							}
						}

						& > span {
							font-size: 16px;
							color: #1d2129;
							position: absolute;
							top: 90px;
							left: 50px;
						}
					}
				}

				& > .ant-radio-group {
					margin-top: 20px;
				}

				& > .switch {
					margin: 20px 0;
					font-size: 14px;
					//font-weight: bold;
					color: #1d2129;
					display: flex;
					align-items: center;
					height: 22px;
					line-height: 22px;

					& > .ant-switch {
						margin-left: 5px;
					}
				}

				& > .tags {
					.ant-tag {
						margin-top: 8px;
					}
				}
			}
		}
	}
}

.forecast-ipcRec-proModal-inner {
	& > p {
		font-size: 14px;
		font-weight: bold;
		color: #86909c;

		& > span {
			font-size: 14px;
			font-weight: bold;
			color: #1d2129;
		}
	}
}

.forecast-drawer-inner {
	& > h3 {
		margin-bottom: 10px;
		height: 20px;
		font-size: 14px;
		font-weight: bold;
		color: #1d2129;
		line-height: 20px;
	}

	& > p {
		margin-top: 10px;
		height: 17px;
		font-size: 12px;
		font-weight: bold;
		color: #4e5969;
		line-height: 17px;
	}
}

.ipc-box-hover {
	max-width: 860px !important;

	.ant-tooltip-inner {
		word-break: break-all;
		padding: 10px 30px;
	}
}
</style>
