import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from '@/plugins/axios';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/home.vue')
	},
	{
		path: '/forecast',
		name: 'forecast',
		component: () => import('@/views/forecast.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	// await axios.post('/service/user/logOut', {
	// 	username: 'nt_class'
	// });
	// await axios.post('/service/user/checkUserLogin', {
	// 	username: 'nt_class',
	// 	password: '123456'
	// });
	next();
});

export default router;
