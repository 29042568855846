<template>
	<div class="home">
		<img src="@/assets/image/fen_lbiaot.png" alt="" />

		<a-form layout="vertical" :form="requestData">
			<div class="form-title">
				<b>基础信息</b>
			</div>

			<a-form-item label="发明名称" required>
				<a-input placeholder="请输入发明名称" :maxLength="40" v-model.trim="requestData.title" allowClear />
			</a-form-item>
			<a-form-item>
				<span slot="label">
					申请人
					<a-tooltip title="系统会自动对同申请人且相关度较高的专利进行标记">
						<a-icon type="question-circle-o" />
					</a-tooltip>
				</span>
				<a-input placeholder="多个申请人用“；”隔开" :maxLength="50" v-model.trim="requestData.applicantNames" allowClear />
			</a-form-item>
			<a-form-item>
				<span slot="label">
					保护中心
					<a-tooltip title="预设后，保护中心的预测结果将高亮展示关注的保护中心">
						<a-icon type="question-circle-o" />
					</a-tooltip>
				</span>
				<a-select placeholder="预设关注的保护中心" mode="multiple" v-model="requestData.centers" allowClear>
					<a-select-option
						v-for="(item, index) in commonData.centers"
						:key="index"
						:value="item"
						:disabled="requestData.centers.length >= 10 && !requestData.centers.includes(item)">
						{{ item }}
					</a-select-option>
				</a-select>
			</a-form-item>

			<div class="form-title">
				<b>
					<i>*</i>
					技术信息
				</b>
				<span>至少填写一项</span>
				<a-space style="margin-left: 25px" :size="15">
					<a-button type="primary" size="small" icon="upload" @click="upDoc" :loading="upLoading">
						{{ upLoading ? '解析中' : '上传文档' }}
					</a-button>
					<a-button type="primary" size="small" icon="download" @click="downTmp">下载模板</a-button>
				</a-space>
			</div>

			<a-form-item label="说明书摘要">
				<a-textarea :maxLength="300" placeholder="请输入说明书摘要" v-model.trim="requestData.abs" allowClear />
			</a-form-item>
			<a-form-item label="权利要求书">
				<a-textarea placeholder="请输入权利要求书" v-model.trim="requestData.claims" allowClear />
			</a-form-item>
			<a-form-item label="说明书">
				<a-textarea placeholder="请输入说明书" v-model.trim="requestData.instrPath" allowClear />
			</a-form-item>
			<a-form-item v-if="false">
				<a-checkbox v-model="isCheck">
					我已阅读并同意
					<a href="">《服务协议》</a>
				</a-checkbox>
			</a-form-item>
			<a-form-item extra="无痕模式采用双向加密传输信息，服务器对信息“阅后即焚”不做任何保存，充分保护用户信息不被泄露">
				<a-button type="primary" @click="onSubmit" :loading="timeOut.count !== 0 || loading">
					{{ timeOut.count === 0 ? '无痕预测' : `排队中（${timeOut.count}）...` }}
				</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
export default {
	name: 'HomeView',
	components: {},
	data() {
		return {
			isCheck: true,
			loading: false,
			upLoading: false,
			requestData: {
				title: '',
				// title: '基于接入型OTN设备的网络拓扑实现及动态更新的方法和系统',
				abs: '',
				claims: '',
				instrPath: '',
				applicantNames: '',
				centers: [],
				rankWords: []
			},
			commonData: {
				centers: []
			},
			timeOut: {
				count: 0,
				time: null
			}
		};
	},
	methods: {
		async init() {
			let _this = this;
			_this.$axios.get('/api/service/classifyPrediction/getCenterList').then(res => {
				_this.commonData.centers = res.data.data;
			});
		},
		downTmp() {
			window.open('/execl/分类号预测上传模板.docx', '_blank');
		},
		upDoc() {
			let _this = this;
			let input = document.createElement('input');
			input.type = 'file';
			input.accept = '.docx';
			input.click();
			input.onchange = function (e) {
				let file = e.target.files[0];
				let form = new FormData();
				form.append('file', file);
				_this.upLoading = true;
				_this.$axios.post('/api/service/Precensor/parseWord', form).then(res => {
					_this.upLoading = false;
					if (res.data.code === 200) {
						_this.requestData.abs = res.data.data.abs;
						_this.requestData.claims = res.data.data.claims;
						_this.requestData.instrPath = res.data.data.instrPath;
					} else {
						_this.$message.error(res.data.msg);
					}
				});
			};
		},
		async onSubmit() {
			let _this = this;

			if (!_this.requestData.title) {
				// this.$warning({
				// 	title: '提示',
				// 	content: '请输入发明名称',
				// 	maskClosable: true,
				// 	okText: '我知道了'
				// });
				_this.$message.warning('请输入发明名称');
				return false;
			}
			if (!_this.requestData.abs && !_this.requestData.claims && !_this.requestData.instrPath) {
				_this.$message.warning('技术信息至少填写一项');
				return false;
			}
			if (!_this.isCheck) {
				_this.$message.warning('请勾选服务协议，表示已阅读并同意服务协议');
				return false;
			}

			let form = new FormData();
			let uuid = this.$uuid();
			form.append('type', 2);
			form.append('uuid', uuid);
			_this.loading = true;
			let {
				data: { data: count }
			} = await _this.$axios.post('/api/service/companyReview/getReportWaitTime', form);
			_this.loading = false;
			if (count === 0) {
				request_up(uuid);
			} else {
				_this.timeOut.count = count;
				this.timeOut.time = setInterval(async () => {
					_this.timeOut.count--;
					if (_this.timeOut.count === 0) {
						clearInterval(_this.timeOut.time);
						request_up(uuid);
						// await _this.$axios.post('/api/service/companyReview/removeReportWaitTime', { uuid: _this.$uuid });
					}
				}, 1000);
			}

			async function request_up(uuid) {
				let obj = {
					..._this.requestData,
					...{ applicantNames: _this.requestData.applicantNames.length === 0 ? [] : _this.requestData.applicantNames.split('；') }
				};

				_this.loading = true;
				let { data } = await _this.$axios.post('/api/service/classifyPrediction/prediction', obj, {
					headers: {
						apiUuid: uuid
					}
				});
				_this.loading = false;
				if (data.code === 200) {
					_this.$message.success(data.msg);

					_this.$emit('gotoPage', 'ForecastView', { req: obj, res: data.data });
				} else {
					_this.$message.error(data.msg);
				}
			}
		}
	},
	beforeMount() {
		this.init();
	},
	mounted() {}
};
</script>

<style lang="less">
.home {
	width: 1200px;
	margin: 0 auto;

	& > img {
		height: 30px;
		margin: 26px auto 21px;
		display: block;
	}

	.ant-form {
		& > .form-title {
			width: 100%;
			height: 42px;
			padding: 10px 20px;
			margin: 0 0 24px;
			background: #f0f3f8;
			display: flex;

			& > b {
				height: 22px;
				line-height: 22px;
				font-size: 16px;
				font-weight: bold;
				color: #1d2129;
				display: flex;

				& > i {
					width: 9px;
					height: 22px;
					font-size: 16px;
					color: #ff4d4f;
					line-height: 22px;
					margin-right: 2px;
				}
			}

			& > span {
				height: 20px;
				font-size: 14px;
				font-weight: bold;
				color: #4e5969;
				line-height: 21px;
				margin-left: 5px;
			}
		}

		& > .ant-form-item {
			padding: 0 20px;
		}
	}
}
</style>
