import { render, staticRenderFns } from "./herder.vue?vue&type=template&id=a0b94148&scoped=true&"
var script = {}
import style0 from "./herder.vue?vue&type=style&index=0&id=a0b94148&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0b94148",
  null
  
)

export default component.exports