<template>
	<div id="app">
		<Herder />
		<!--		<router-view />-->
		<component :is="componentName" @gotoPage="gotoPage" :pageData="pageData"></component>

		<Footer />
	</div>
</template>

<script>
import Herder from '@/components/herder.vue';
import Footer from '@/components/footer.vue';
import HomeView from '@/views/home.vue';
import ForecastView from '@/views/forecast.vue';

export default {
	components: {
		Herder,
		Footer,
		HomeView,
		ForecastView
	},
	data() {
		return {
			componentName: 'HomeView',
			pageData: {}
		};
	},
	methods: {
		gotoPage(name, data) {
			this.componentName = name;
			this.pageData = data;
		}
	},
	async beforeMount() {
		let obj = {
			username: 'nt_class',
			password: '123456',
			determine: '1'
		};
		let data = await this.$axios.post('/api/service/user/checkUserLogin', this.$qs.stringify(obj));
		localStorage.setItem('token', data.headers.token);
		// let form = new FormData();
		// form.append('type', 2);
		// form.append('uuid', this.$uuid);
		// let {data:{data:count}} = await this.$axios.post('/api/service/companyReview/getReportWaitTime', form);
		// console.log(count)
		// await this.$axios.post('/api/service/companyReview/removeReportWaitTime', { uuid: this.$uuid });
	}
};
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';
@import '@/assets/style/main.less';
</style>
