import Vue from 'vue';
import Antd from 'ant-design-vue';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';

Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$uuid = uuid;
Vue.prototype.$utils = require('./utils/index');

Vue.config.productionTip = false;

Vue.use(Antd);

new Vue({
	router,
	store,
	render: function (h) {
		return h(App);
	}
}).$mount('#app');
