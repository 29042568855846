import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async ResumeDefault_Up({}, obj) {
			let { data } = await axios.post('/api/Resume/ResumeDefault_Up', obj);
			return data;
		}
	},
	modules: {}
});
